import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "intro-y flex flex-col sm:flex-row items-center mt-8" }
const _hoisted_2 = { class: "text-lg font-medium mr-auto" }
const _hoisted_3 = { class: "intro-y box p-5 mt-5" }
const _hoisted_4 = { class: "overflow-x-auto scrollbar-hidden mt-2 wysiwyg" }
const _hoisted_5 = { class: "flex flex-row-reverse mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CKEditor = _resolveComponent("CKEditor")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$breadcrumb[_ctx.$breadcrumb.length-1].title), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_CKEditor, _mergeProps({
          modelValue: _ctx.template.Content.Content,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.template.Content.Content) = $event))
        }, _ctx.editorOptions), null, 16, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("button", {
          type: "button",
          class: "button w-full sm:w-16 bg-theme-1 text-white",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)))
        }, " 儲存 ")
      ])
    ])
  ]))
}